import React from 'react';
import Item from '../Item/Item';

import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const ItemList = ({ products }) => {
    return (
        <div>
            <Swiper
                slidesPerView={2} // Valor predeterminado para pantallas pequeñas
                spaceBetween={10}
                initialSlide={0}
                loop={true}
                pagination={{ clickable: true }}
                autoplay={{ delay: 2000, disableOnInteraction: false }}
                navigation={true}
                modules={[Autoplay]}
                className="mySwiper swiper-container"
                breakpoints={{
                    640: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                    },
                    1024: {
                        slidesPerView: 6,
                        spaceBetween: 10,
                    },
                    1280: {
                        slidesPerView: 8,
                        spaceBetween: 10,
                    },
                }}
            >
                {products.filter(product => product.activo === "si").map(prod => (
                    <SwiperSlide key={prod.id} className="swiper-slide">
                        <div className="clientes-card" key={prod.id}>
                            <div className="img" style={{ backgroundImage: `url('../img/clientes/${prod.id}.jpg')` }} title={prod.nombre}></div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default ItemList;
