import React from "react";

import './styles.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Footer from './components/Footer/Footer';
import NavBar from './components/NavBar/NavBar';
import Home from './components/Home/Home';
import NotA404 from "./components/Assets/NotA404";
import MKTExterno from "./components/MKTExterno/MKTExterno";
import MKTDigital from "./components/MKTDigital/MKTDigital";

const App = () => {

  return (
    <div className='appContainer'>
      <BrowserRouter>
          <header>
            <NavBar />
          </header>
          <main>
            <Routes>
              <Route path='/' element={ <Home /> }/>
              <Route path='/marketing-externo' element={ <MKTExterno /> }/>
              <Route path='/marketing-digital' element={ <MKTDigital /> }/>
              <Route path='*' element={ <NotA404 /> }/>
            </Routes>
          </main>
          <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;