import React from "react";

const BannerHome = () => {

    return(
        <section>
            <div className="banner-home" style ={ {backgroundImage: `url('../img/banner-home.jpg')` } }>
                <div className="container">
                    <img src="../img/logoB.png" alt="MOMO" />
                    <a href="#contacto" className="btnB">Hablemos</a>
                </div>
            </div>
        </section>
    )
}

export default BannerHome