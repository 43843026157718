import React from "react";

import { Link } from 'react-router-dom';

const NotA404 = () => {
    return (
        <section className='notA404'>
            <Link to="/">
                <div>
                    <img src="../../img/logoB.png" alt="logo"/>
                </div>
            </Link>
            <h4>
                UPS! ALGO PASÓ
            </h4>
            <Link to="/" className="btnB">
                VOLVER
            </Link>
        </section>
    )
}

export default NotA404