import React from "react";

import { Link } from 'react-router-dom';

const Hacemos = () => {
    
    return(
        <section id="hacemos">
            <div className="hacemos">
                <div className="container bkg-magenta">
                    <Link to="marketing-digital" className="btnB">
                        MARKETING DIGITAL
                    </Link>
                </div>
                <div className="container bkg-cyan">
                    <Link to="/marketing-externo" className="btnB">
                        MARKETING EXTERNO
                    </Link>
                </div>
                <div className="container bkg-red">
                    <Link to="/" className="btnB">
                        DIGITALIZACIÓN
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Hacemos