import React, { useEffect }  from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";

import ContactoForm from "../ContactoForm/ContactoForm";

const MKTDigital = () => {

    useEffect(() => {
        logEvent(analytics, window.location.pathname);
        window.scrollTo(0, 0);
    }, []);
    
    return(
        <section>
            <div className="mkt-digital">
                <div className="banner" style ={ {backgroundImage: `url('../img/banner-digital.jpg')` } }>
                    <h2>Marketing Digital</h2>
                </div>
                <article>
                    En el dinámico y competitivo mundo digital actual, contar con una sólida estrategia de marketing es fundamental para el éxito de cualquier negocio. Nuestra agencia de marketing digital ofrece una amplia gama de servicios diseñados para ayudarte a alcanzar tus objetivos comerciales y maximizar tu presencia en línea. A continuación, te presentamos los principales servicios que brindamos:
                    <br/><br/>
                    <span className="span1">Estrategia de Marketing digital</span>
                    <br/>
                    Desarrollamos estrategias personalizadas basadas en un análisis profundo de tu negocio y mercado objetivo. Identificamos oportunidades y creamos un plan integral que incluye acciones específicas para alcanzar tus metas.
                    <br/><br/>
                    <span className="span2">Publicidad Online (SEM)</span>
                    <br/>
                    Gestionamos campañas de publicidad online en plataformas como Google Ads y redes sociales. Creamos anuncios atractivos y segmentados que llegan a tu público objetivo, aumentando las posibilidades de conversión y retorno de inversión.
                    <br/><br/>
                    <span className="span3">Gestión de Redes Sociales</span>
                    <br/>
                    Administramos tus perfiles en redes sociales, creando contenido relevante y atractivo que fomente la interacción con tu audiencia. Llevamos a cabo campañas específicas para aumentar tu alcance y engagement, fortaleciendo la relación con tus seguidores.
                    <br/><br/>
                    <div className="banner" style ={ {backgroundImage: `url('../img/banner-digital-2.jpg')` } }></div>

                    <span className="span4">Marketing de Contenidos</span>
                    <br/>
                    Generamos contenido de valor que resuene con tu audiencia y refuerce tu marca. Desde artículos de blog hasta videos y gráficos, creamos materiales que educan, informan y entretienen a tus clientes potenciales.
                    <br/><br/>
                    <span className="span1">Email Marketing</span>
                    <br/>
                    Diseñamos y ejecutamos campañas de email marketing efectivas que mantienen a tu audiencia informada y comprometida. Segmentamos tus listas de contactos para enviar mensajes personalizados y relevantes que fomenten la conversión y la lealtad.
                    <br/><br/>
                    <span className="span2">Diseño y Desarrollo de Páginas Web</span>
                    <br/>
                    Creamos sitios web atractivos, funcionales y optimizados para la conversión. Nos aseguramos de que tu página web refleje la identidad de tu marca y brinde una experiencia de usuario excepcional. Además, implementamos técnicas de SEO on-page para garantizar que tu sitio sea fácilmente encontrado por los motores de búsqueda.
                    <br/><br/>
                    <span className="span3">Análisis y Reportes</span>
                    <br/>
                    Realizamos un seguimiento constante de todas las acciones y campañas, proporcionando informes detallados sobre el rendimiento de cada una. Analizamos los datos para ajustar las estrategias y asegurar que se cumplan tus objetivos.
                </article>
            </div>
            <ContactoForm />
        </section>
    )
}

export default MKTDigital