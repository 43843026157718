import React from "react";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="container-links">
                    <div className="links">
                        <Link to="/#nosotros" className="button">Nosotros</Link>  
                        <Link to="/#hacemos" className="button">Hacemos</Link>  
                        <Link to="/#clientes" className="button">Clientes</Link>  
                        <Link to="/#contacto" className="button" >Contacto</Link>  
                    </div>
                </div>
                <div className="container-logo">
                    <Link to="/" >
                        <img src="../../img/logoN.png" alt="MOMO" className="logo"/>
                    </Link>    
                </div>
            </div>
        </footer>
    )
}

export default Footer