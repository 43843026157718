import React, { useEffect }  from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";

import { Link } from 'react-router-dom';
import ContactoForm from "../ContactoForm/ContactoForm";

const MKTExterno = () => {

    useEffect(() => {
        logEvent(analytics, window.location.pathname);
        window.scrollTo(0, 0);
    }, []);
    
    return(
        <section>
            <div className="mkt-externo">
                <h2>Departamento de Marketing Externo</h2>
                <article>
                    En un entorno empresarial cada vez más competitivo y saturado, es crucial ser profesional en todas las áreas del negocio. Hoy en día, no alcanza con tener una sola persona a cargo del marketing; es fundamental contar con un equipo de expertos en diversas áreas para lograr nuestros objetivos comerciales. Tener a los mejores especialistas garantiza obtener los mejores resultados.
                    <br/><br/>
                    La externalización del departamento de marketing te brinda acceso a un equipo de profesionales que diseñará y ejecutará la estrategia y el plan de marketing de tu empresa, llevando a cabo las acciones necesarias para impulsar tus ventas: posicionamiento SEO, Adwords, redes sociales, fidelización de clientes, entre otros. Además de gestionar la parte operativa de las acciones planificadas, nos encargamos de coordinar todos los recursos necesarios para implementar el plan: diseñadores, programadores web, empresas de telemarketing, gabinetes de prensa y todo lo que sea necesario para alcanzar los objetivos establecidos.
                </article>
                <div className="banner" style ={ {backgroundImage: `url('../img/banner-externo.jpg')` } }>
                </div>
                <h3>¿Ya contás con estos servicios?</h3>
                <article>
                    Necesitás un Director de Marketing Externo que se encargue de coordinar todos los recursos para alcanzar eficientemente tus objetivos comerciales. Esta figura clave coordinará tus acciones y estrategias, asegurando la coherencia necesaria en toda la comunicación de tu empresa. Además, te ayudará a lograr tus metas mediante una gestión óptima de todos los recursos externos, maximizando el beneficio de tus soluciones de outsourcing y ahorrando dinero al negociar los mejores precios con ellos.								
                </article>
            </div>
            <ContactoForm />
        </section>
    )
}

export default MKTExterno