import React from "react";
import ItemList from "../ItemList/ItemList";
import Loading from "../Assets/Loading";
import NotA404 from "../Assets/NotA404";

import { useParams } from 'react-router-dom';
import { getProducts } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';


const Clientes = () => {
    
    const { categoryId } = useParams()
    const { isLoading, data, error } = useFirestore(() => getProducts(categoryId), [categoryId])

    if(error) {
        return <NotA404 />
    }
    
    return(
        <section id="clientes">
            <div className="clientes">
                {
                    isLoading
                    ? <Loading />
                    : <ItemList products={data} />
                }
            </div>
        </section>
    )
}

export default Clientes