import React from "react";

const Intro = () => {

    return(
        <section id="nosotros">
            <div className="intro">
                <article>
                    Somos una agencia de <span className="span2">marketing digital</span> con más de 15 años de experiencia y estudios en marketing, creatividad publicitaria, producción, diseño y comunicación digital donde <span className="span1">ayudamos a pequeñas y medianas empresas </span> a posicionarse en el mercado. Nos ocupamos de tus necesidades creativas y estratégicas para que puedas concentrarte en hacer crecer tu negocio.
                    <br/><br/>
                    <span className="span3">Estamos siempre dispuestos a los nuevos desafíos.</span>
                    <br/><br/>
                    Sabemos cómo hacer lo que hay que hacer.
                </article>
            </div>
        </section>
    )
}

export default Intro