import React, { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";
import BannerHome from "./Banners/BannerHome";
import Intro from "./Intro/Intro";
import ContactoForm from "../ContactoForm/ContactoForm";
import Clientes from "../Clientes/Clientes";
import Hacemos from "../Hacemos/Hacemos";
import { useLocation } from "react-router-dom";

const Home = () => {
    const { hash } = useLocation();

    useEffect(() => {
        logEvent(analytics, window.location.pathname);
        window.scrollTo(0, 0);
        
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 100); // Agrega un pequeño retraso para asegurar que la página se haya cargado completamente
            }
        }
    }, [hash]);

    return (
        <div>
            <BannerHome />
            <Intro />
            <Hacemos />
            <Clientes />
            <ContactoForm />
        </div>
    );
}

export default Home;
