import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyA4_C1r_xxWoEeDJgtiOLxz-_THyyDwzIs",
  authDomain: "momo-web-215b8.firebaseapp.com",
  projectId: "momo-web-215b8",
  storageBucket: "momo-web-215b8.appspot.com",
  messagingSenderId: "371649595886",
  appId: "1:371649595886:web:1709f2fe2378d4e608e6ff"
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics();

export const db = getFirestore(app);

